import isFunction from 'growth-payments-core/lodash/isFunction';

// An object that allows fetching a stored value

// An object that allows fetching a stored value

// An object that allows subscribing to changes in a stored value

const bindingInstances = [];

/**
 * ⚠️ Should only be called as part of build time tests
 */
export function cleanupForTesting() {
  for (const bindingInstance of bindingInstances) {
    bindingInstance.cleanupForTesting();
  }
}

/**
 * Binding
 * An object that can store a gettable/settable value, and allow subscriptions to changes in that value
 * This can eventually be configured to be transparent with the use of a Proxy, but they aren't supported at HubSpot yet
 */
export default class Binding {
  constructor(initialValue) {
    this.setters = new Set();
    this.initialValue = initialValue;
    this.value = initialValue;
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
    bindingInstances.push(this);
  }
  get() {
    return this.value;
  }
  set(newValue) {
    if (isFunction(newValue)) {
      this.value = newValue(this.value);
    } else {
      this.value = newValue;
    }
    this.setters.forEach(setter => setter(this.value));
  }
  subscribe(value) {
    return this.setters.add(value);
  }

  /**
   * ⚠️ Should only be called as part of build time tests
   */
  cleanupForTesting() {
    // Release network request cache for allowing different network requests responses to be mocked
    this.value = this.initialValue;
  }
  unsubscribe(value) {
    return this.setters.delete(value);
  }
}