const emptyFunction = () => {};

// use this instead of console.log to not log stuff in production
export const debug = {
  log: emptyFunction,
  error: emptyFunction,
  warn: emptyFunction
};
export const logAndReturn = name => param => {
  debug.log(name, param);
  return param;
};
export default debug;