import GettingStartedSharedTasksStore from './internal/GettingStartedSharedTaskStore';
let gettingStartedSharedTasksStoreInstance = null;
const getStore = () => {
  if (!gettingStartedSharedTasksStoreInstance) {
    gettingStartedSharedTasksStoreInstance = new GettingStartedSharedTasksStore();
  }
  return gettingStartedSharedTasksStoreInstance;
};

// DO NOT USE: only exported for testing purposes
export const deleteStoreInstance = () => {
  gettingStartedSharedTasksStoreInstance = null;
};
export default getStore;