export const getNetworkBindingData = networkBinding => {
  const data = networkBinding.get();

  // if data exists, return a promise that resolves to the data
  if (data !== null) {
    return Promise.resolve(data);
  }

  // if data does not exist and there is no fetch in progress, make a new fetch request
  if (!networkBinding.fetching) {
    return networkBinding.fetch().then(response => response).catch(error => {
      throw Error(`Network binding fetch failed. Message: ${error}`);
    });
  }

  // if a fetch is in progress, subscribe to any changes to the binding data
  return new Promise(res => networkBinding.subscribe(res));
};