import { createSyncLocalSuperstore } from 'superstore';
const GETTING_STARTED_SHARED_TASKS_STORE_NAMESPACE = 'getting-started-shared-tasks-store';
const DEFAULT_TASK_MAX_AGE = 60 * 60 * 1000; // 1 hour

export default class GettingStartedSharedTasksStore {
  constructor() {
    this.store = createSyncLocalSuperstore({
      namespace: GETTING_STARTED_SHARED_TASKS_STORE_NAMESPACE
    });
  }
  getTask(taskKey) {
    try {
      const rawDataFromLocalStorage = this.store.get(taskKey);
      return rawDataFromLocalStorage ? JSON.parse(rawDataFromLocalStorage) : null;
    } catch (_unused) {
      return null;
    }
  }
  removeTask(taskKey) {
    try {
      this.store.delete(taskKey);
      return true;
    } catch (_unused2) {
      return false;
    }
  }
  markTaskAsStarted(taskKey, properties = {}) {
    try {
      this.store.set(taskKey, JSON.stringify(Object.assign({
        startedAt: Date.now()
      }, properties)));
    } catch (_unused3) {
      // no-op
    }
  }
  getTaskStartedWithingTimeframe(taskKey, options) {
    const {
      maxAge = DEFAULT_TASK_MAX_AGE,
      removeTaskIfExpired = true
    } = options || {};
    const task = this.getTask(taskKey);
    if (!task || !task.startedAt) {
      return null;
    }
    const isTaskExpired = Date.now() - task.startedAt > maxAge;
    if (removeTaskIfExpired && isTaskExpired) {
      this.removeTask(taskKey);
    }
    return isTaskExpired ? null : task;
  }
  hasTaskBeenStarted(taskKey, options) {
    const task = this.getTaskStartedWithingTimeframe(taskKey, options);
    return Boolean(task);
  }
}