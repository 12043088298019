/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useEffect } from 'react';
import useBinding from './useBinding';
export function useNetworkBinding(binding, defaultValue) {
  const [value] = useBinding(binding);
  useEffect(() => {
    if ((value === null || value === undefined) && !binding.fetching) {
      binding.fetch();
    }
  }, [binding, binding.fetching, value]);
  return [value !== null && value !== undefined ? value : defaultValue || null, binding.fetch, binding.fetching, binding.error];
}
export function useInitNetworkBinding(binding) {
  useEffect(() => {
    if (!binding.get() && !binding.fetching) {
      binding.fetch();
    }
  }, [binding]);
}
export const createNetworkHook = binding => defaultValue => useNetworkBinding(binding, defaultValue);