import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["property"];
import { fetchGroupedProperties } from './propertiesApi';
import { deepFreeze } from '../cache/deepFreeze';
import { makeGroupsWithPropertiesQuickFetchKey } from 'framework-data-schema-quick-fetch';
import { stringCollator } from '../utils/stringCollator';
import { createInMemoryCache } from '../cache/createInMemoryCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { handleExtraProcessingResult, processExtraPropertyMetrics } from './extraPropertyMetrics';
import { normalizeCustomIdentifier } from '../utils/normalizeCustomIdentifier';
import { keyBy } from '../utils/keyBy';
import { wrapPromise } from 'persist-promise/wrapPromise';
import { isEarlyCacheReturnEnabled } from '../earlyCacheReturn';
const normalizeForPatchDiffing = (groups, env) => env.keyBy(groups, group => group.name, group => {
  if (!group.propertyDefinitions) {
    return group;
  }
  return Object.assign({}, group, {
    propertyDefinitions: env.keyBy(group.propertyDefinitions, pd => pd.property.name, pd => {
      if (!pd.rollupExpression || !pd.rollupExpression.associationTypes) {
        return pd;
      }
      return Object.assign({}, pd, {
        rollupExpression: pd.rollupExpression.associationTypes.reduce((acc, associationSpec) => {
          const {
            associationCategory,
            associationTypeId
          } = associationSpec;
          acc[`${associationCategory}-${associationTypeId}`] = associationSpec;
          return acc;
        }, {})
      });
    })
  });
});
const segmentKey = key => {
  const match = key.match(makeGroupsWithPropertiesQuickFetchKey({
    portalId: '.*',
    frameworkTypeIdentifier: '(.*)'
  }));
  if (!match || !match[1]) {
    return null;
  }
  return normalizeCustomIdentifier(match[1]);
};
const propertiesPersistedPromise = wrapPromise({
  namespace: 'FDSR',
  entityName: 'properties',
  deepFreeze: true,
  toCacheKey: makeGroupsWithPropertiesQuickFetchKey,
  fetch: fetchGroupedProperties,
  metricsConfig: {
    convertKeyToMetricsDimension: segmentKey,
    enablePatchDiffing: true,
    normalizeForPatchDiffing,
    additionalProcessing: {
      process: processExtraPropertyMetrics,
      handleResult: handleExtraProcessingResult
    }
  }
});
const defaultPropertiesOperationCache = createInMemoryCache({
  cacheName: 'properties-ops'
});
const makeOperationCacheKey = ({
  operationName,
  frameworkTypeIdentifier,
  query,
  portalId
}) => `${makeGroupsWithPropertiesQuickFetchKey({
  portalId,
  frameworkTypeIdentifier,
  query
})}-${operationName}`;
export const makePropertiesClient = ({
  httpClient,
  persistedPromise = propertiesPersistedPromise,
  operationCache = defaultPropertiesOperationCache,
  toOperationCacheKey = makeOperationCacheKey
}) => {
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      persistedPromise.debug();
      operationCache.printDebug();
    },
    /**
     * Clears internal cache state.
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([operationCache.clear(), persistedPromise.clearCache()]);
    },
    /**
     * Gets all property groups and their properties for a framework type.
     *
     * @param options.frameworkTypeIdentifier A unique id for the framework type, such as `objectTypeId` or `fullyQualifiedName`.
     * @param options.query.showHighlySensitiveProperties Whether highly-sensitive properties should be visible.
     * @param options.refetch Bypasses the cache and triggers a fresh network request, writing the result to the cache.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to an array of all property groups in this portal, or null if the data could not be found.
     */
    getGroups: async ({
      frameworkTypeIdentifier,
      query,
      refetch = false,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('properties.getGroups').increment();
      }
      const operationCacheKey = toOperationCacheKey({
        operationName: 'getGroupsForType',
        frameworkTypeIdentifier,
        query
      });
      const result = await operationCache.readThrough({
        cacheKey: operationCacheKey,
        refresh: refetch,
        loadValue: async () => {
          const results = await persistedPromise.makeFetchWithOpts({
            allowEagerCacheReturn: isEarlyCacheReturnEnabled(),
            refresh: refetch
          })({
            httpClient,
            frameworkTypeIdentifier,
            query: Object.assign({}, query, {
              // HACK: showHighlySensitiveProperties: false (the default) sets the properties BE to hide
              // all properties marked as highly sensitive. This is very wasteful — only a small fraction
              // of properties are highly sensitive, and the only change is their `hidden` flag, but apps/libraries
              // frequently need both sides of the query param.
              //
              // To avoid making two requests, we'll make the request with showHighlySensitiveProperties: true
              // and then duplicate the BE's logic in the properties client. So, no matter what they requested,
              // we'll pass `true` here so that we get the true `hidden` value of the properties, and set their
              // `hidden` flags accordingly based on what the user wanted.
              showHighlySensitiveProperties: true
            })
          });

          // If the user has requested for the HSPs to be shown, we can return the results as-is
          if (query !== null && query !== void 0 && query.showHighlySensitiveProperties) {
            return results;
          }

          // Otherwise, we need to set any property with a dataSensitivity level of 'high' to be hidden.
          // This logic must mirror the logic in the BE: https://git.hubteam.com/HubSpotProtected/InboundDbProperties/blob/3a9dbb099b1ed16a2bdf16b9b010cf8b0db42207/InboundDbPropertiesService/src/main/java/com/hubspot/inbounddb/properties/service/helpers/PropertiesResponseTransform.java#L56-L72
          return deepFreeze(results.map(group => {
            var _group$propertyDefini;
            return Object.assign({}, group, {
              propertyDefinitions: (_group$propertyDefini = group.propertyDefinitions) === null || _group$propertyDefini === void 0 ? void 0 : _group$propertyDefini.map(_ref => {
                let {
                    property
                  } = _ref,
                  propertyDefinition = _objectWithoutPropertiesLoose(_ref, _excluded);
                return Object.assign({}, propertyDefinition, {
                  property: Object.assign({}, property, property.dataSensitivity === 'high' && {
                    hidden: true
                  })
                });
              })
            });
          }));
        }
      });
      return result !== null && result !== void 0 ? result : makeLoadFailed();
    },
    /**
     * Gets all properties for a framework type (derived from the request for all property groups).
     *
     * Properties are sorted by label || name.
     *
     * @param options.frameworkTypeIdentifier A unique id for the framework type, such as `objectTypeId` or `fullyQualifiedName`.
     * @param options.query.showHighlySensitiveProperties Whether highly-sensitive properties should be visible.
     * @param options.refetch Bypasses the cache and triggers a fresh network request, writing the result to the cache.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to an array of all properties in this portal, or null if the data could not be found.
     */
    get: ({
      frameworkTypeIdentifier,
      query,
      refetch = false,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('properties.get').increment();
      }
      const operationCacheKey = toOperationCacheKey({
        operationName: 'getForType',
        frameworkTypeIdentifier,
        query
      });
      const cachedValue = operationCache.readThrough({
        cacheKey: operationCacheKey,
        refresh: refetch,
        loadValue: () => client.getGroups({
          frameworkTypeIdentifier,
          query,
          refetch,
          __isComposed: true
        }).then(groups => deepFreeze(groups.map(({
          propertyDefinitions
        }) => propertyDefinitions || []).flat(1).sort((a, b) => stringCollator.compare(a.property.label || a.property.name, b.property.label || b.property.name))))
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    },
    /**
     * Gets one property for a framework type. This can be used instead of manually filtering down the response from `.get()`.
     *
     * @param options.frameworkTypeIdentifier A unique id for the framework type, such as `objectTypeId` or `fullyQualifiedName`.
     * @param options.propertyName The property's internal name.
     * @param options.query.showHighlySensitiveProperties Whether highly-sensitive properties should be visible.
     * @param options.refetch Bypasses the cache and triggers a fresh network request, writing the result to the cache.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to this property, or null if the data could not be found.
     */
    getProperty: ({
      frameworkTypeIdentifier,
      propertyName,
      query,
      refetch = false,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('properties.getProperty').increment();
      }
      const propertyMapCacheKey = toOperationCacheKey({
        operationName: 'getForTypeAndName-propertyMap',
        frameworkTypeIdentifier,
        query
      });
      const operationCacheKey = toOperationCacheKey({
        operationName: `getForTypeAndName-${propertyName}`,
        frameworkTypeIdentifier,
        query
      });
      const propertyMapPromise = operationCache.readThrough({
        cacheKey: propertyMapCacheKey,
        refresh: refetch,
        loadValue: () => client.get({
          frameworkTypeIdentifier,
          query,
          refetch,
          __isComposed: true
        }).then(properties => keyBy(properties, ({
          property: {
            name
          }
        }) => name))
      });
      if (propertyMapPromise === null) {
        return makeLoadFailed();
      }
      const cachedValue = operationCache.readThrough({
        cacheKey: operationCacheKey,
        refresh: refetch,
        loadValue: () => propertyMapPromise.then(properties => {
          const property = properties[propertyName];
          if (!property) {
            throw new Error(`Property "${propertyName}" on type "${frameworkTypeIdentifier}" does not exist`);
          }
          return deepFreeze(property);
        })
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    }
  };
  return Promise.resolve(client);
};