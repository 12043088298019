import Binding from './binding';
// An object that allows automatic refetching of a stored value

/**
 * Make a network request,
 * Use a binding to store network responses and make them available in React components.
 */
export default class NetworkBinding extends Binding {
  constructor(fetcher) {
    super(null);
    this.fetching = false;
    this.error = null;
    this.fetcher = fetcher;
    this.fetch = this.fetch.bind(this);
  }
  fetch() {
    if (this.fetching) {
      return Promise.resolve(this.get());
    }
    this.fetching = true;
    this.error = null;
    return this.fetcher().then(data => {
      super.set(data);
      return data;
    }).catch(error => {
      this.error = error;
      return null;
    }).finally(() => {
      this.fetching = false;
    });
  }
  set() {
    throw new Error("Can't set on a network binding");
  }
  cleanupForTesting() {
    this.fetching = false;
    super.cleanupForTesting();
  }
}