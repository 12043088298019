import { useMemo } from 'react';
import { useGetHasScope } from '../../userInfo/hooks/useGetHasScope';
export const useHasFeatureWriteAccess = feature => {
  const hasScope = useGetHasScope();
  return useMemo(() => {
    // No feature entry means no write access
    if (!feature) {
      return false;
    }

    // No scopes means no write access
    if (!feature.writeScopes || !feature.writeScopes.length) {
      return false;
    }

    // Must have all scopes for write access
    return feature.writeScopes.every(scope => hasScope(scope));
  }, [feature, hasScope]);
};